<template>
  <div>
    <div class="pititle">
     <div class="zhidingsearch">
        <el-form>
        <div class="litop">
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="标准名称">
                  <el-input
                    v-model="proName"
                    placeholder="请输入您的标准名称"
                    clearable  @keyup.enter.native="search()"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="主编单位">
                  <el-input
                    v-model="firstUnit"
                    placeholder="请输入您的主编单位"
                    clearable  @keyup.enter.native="search()"
                  ></el-input>
                </el-form-item>

              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="标准类型">
                  <el-select v-model="biaozhunType" placeholder="标准类型" clearable  @keyup.enter.native="search()">
                    <el-option label="标准" :value="1"></el-option>
                    <el-option label="标准设计" :value="2"></el-option>
                    <!-- <el-option label="推荐行技术规程" :value="3"></el-option> -->
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="制定类型">
                  <el-select v-model="zhidingType" placeholder="制定类型" clearable  @keyup.enter.native="search()">
                    <el-option label="制定" :value="1"></el-option>
                    <el-option label="修订" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                  <el-button type="primary" @click="search">查询</el-button>
                  <el-button type="info" @click="qingkong">清空</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>
     </div>
      <div class="piconetn">
        <!-- 表格 -->
        <div class="pitable">
          <el-table :data="tableData" :row-class-name="tabRowClassName" style="width: 100%" :header-cell-style="{background:'#3082E2',color:'#FFF'}">
            <!-- <el-table-column
                type="selection"
                width="55">
            </el-table-column> -->
            <el-table-column label="序号" type="index">
            </el-table-column>
            <!-- <el-table-column
              label="批次编号"
              prop="batch"
              align="center"
            >
            </el-table-column> -->
            <el-table-column label="标准名称" prop="proName" align="center">
              <template slot-scope="{row}">
                <router-link :to="gotoStep(row)" class="linkto">
                  <span>{{row.proName}}</span>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column label="标准类型" align="center" width="130px">
              <template slot-scope="{ row }">
                <span v-if="row.biaozhunType == 1">标准</span>
                <span v-if="row.biaozhunType == 2">标准设计</span>
                <!-- <span v-if="row.biaozhunType == 2">推荐性技术规程</span> -->
              </template>
            </el-table-column>
            <el-table-column label="制定类型" align="center" width="130px">
              <template slot-scope="{ row }">
                <span v-if="row.zhidingType == 1">制定</span>
                <span v-if="row.zhidingType == 2">修订</span>
              </template>
            </el-table-column>
            <el-table-column label="主编单位" prop="firstUnit" align="center">
            </el-table-column>
            <el-table-column label="项目负责人" prop="firstProHandler" align="center" width="130px">
            </el-table-column>
            <!-- <el-table-column label="标识星级" align="center">
              <template slot-scope="{ row }">
                <i
                  v-for="item in row.after_stars"
                  :key="item"
                  class="el-icon-star-on"
                ></i>
              </template>
            </el-table-column>
            <el-table-column label="标识阶段" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.stage == 1">能效评测</span>
                <span v-if="row.stage == 2">实测评估</span>
              </template>
            </el-table-column> -->
            <el-table-column label="申请日期" prop="created_at" align="center" width="130px">
              <template slot-scope="{ row }">
                <span>{{
                  row.created_at | formatDate("yyyy-MM-dd")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="项目状态" align="center" width="150px">
                <template slot-scope="{ row }">
                <span v-if="row.status == 1" style="color:#E6A23C">待提交</span>
                <span v-else-if="row.status == 2" style="color:#409EFF">待立项</span>
                <span v-else-if="row.status == 3" style="color:#E6A23C">待修改中</span>
                <span v-else-if="row.status == 4" style="color:#F56C6C">待立项</span>
                <span v-else-if="row.status == 5">立项不通过</span>
                <span v-else-if="row.status == 6" style="color:#E6A23C">待提交初稿</span>
                <span v-else-if="row.status == 7" style="color:#F56C6C">待初稿审查</span>
                <span v-else-if="row.status == 8" style="color:#E6A23C">待完善审查材料</span>
                <span v-else-if="row.status == 9" style="color:#E6A23C">待提交征求意见稿</span>
                <span v-else-if="row.status == 10" style="color:#F56C6C">待征求意见</span>
                <span v-else-if="row.status == 11" style="color:#E6A23C">待提交送审稿及材料</span>
                <span v-else-if="row.status == 12" style="color:#F56C6C">待送审</span>
                <span v-else-if="row.status == 13" style="color:#E6A23C">待提交报批稿</span>
                <span v-else-if="row.status == 14" style="color:#F56C6C">待报批</span>
                <span v-else-if="row.status == 15" style="color:#67C23A">报批</span>
                <span v-else-if="row.status == 16" style="color:#909399">留档</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页 -->
        <div class="pageTotal">
          <div class="block">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                layout="prev, pager, next"
                :total="totalCount"
                :page-size="limit">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//getProjectList  
import { getBiaozhunList ,} from "../../../../api/project";

export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd ";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      //选中的表格数据
      selectedProjects: [],
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      token:'',
      firstUnit: '',
      proName:'',
      zhidingType:'',
      biaozhunType:''
    };
  },
  mounted() {
    // this.getList();
    this.getToken();
    this.getList();
  },
  methods: {
    getToken(){
      this.token = this.$store.state.token//获取cookie字符串
      console.log(this.token);
     // this.getbiaozhunList()
    },
    tabRowClassName({rowIndex}){
      let index = rowIndex + 1;
      if(index %2 == 0){
        return 'warning-row'
      }
    },
    //获得表格数据
      getList(){
      let data={
        limit:this.limit,
        page:this.page,
        token:this.token,
        proName:this.proName,
        firstUnit: this.firstUnit,
        zhidingType:this.zhidingType,
        biaozhunType:this.biaozhunType,
        status:'6,7'
      }
      console.log('条件查询',data)
      getBiaozhunList(data).then(rec =>{
         console.log('lists',rec)
        this.tableData = rec.data
        this.tableData = rec.data
        this.limit = rec.limit
        this.page = rec.page
        this.totalCount = rec.totalCount
        this.totalPage = rec.totalPage
        console.log(rec)
      })
  },

    //搜索
    search() {
      this.page = 1;
      this.getList();
    },
    //清空
    qingkong(){
      this.firstUnit='',
      this.proName='',
      this.zhidingType='',
      this.biaozhunType='',
      this.getList();
    },
    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getList();
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      //console.log(`当前页: ${page}`)
      this.page = page;
      this.getList();
    },
    //表格多选
    selected_change(val){
      this.selectedProjects = val;
      
    },
    gotoStep(row){
      let url = '/technology/Draftreview?id='+row.id;
      return url;
    },
    exportExcel(){  //导出表单
      let url = 'https://greenapi.cdmp.tech/api/standard/export?';
      let idarr = this.selectedProjects.map((item) => {
        return item.id
      });
      let ids=  idarr.toString();
      url+='token='+this.$store.state.token;
      url+= '&ids='+ids;
      window.open(url,'blank');
      console.log(url)
      // getExcel({ids:ids,token:this.token}).then( rec =>{
      //   console.log(rec)
      // })
    }
  },
};
</script>
<style scoped>
/deep/.el-button--primary{
    background-color: #3082e2;
    border-color: #3082e2;
}
/deep/.el-button--primary:hover{
    background-color: #409EFF;
    border-color: #409EFF;
}
/deep/.el-input__suffix {
    right: 43px;
    transition: all .3s;
    border-left: 1px solid #c0c4cc;
}
/deep/.el-table__row .el-table__row--striped{
background-color:  rgba(48, 130, 226, 0.1);
}
/deep/ .el-table th.el-table__cell > .cell{
  min-width: 100px;
}
/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}
/deep/.el-form-item__label {
  font-family: Adobe Heiti Std;
  color: #2D2D2D;
}
/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 204px;
}

/deep/ .el-table th.el-table__cell > .cell {
  font-size: 16px;
}
/deep/.el-table .warning-row{
  background: rgba(48, 130, 226, 0.1);
}
.pitable{
  /*height: 60vh;*/
}
.litop {
  margin: 20px 24px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row-reverse;
  margin: 30px 0;
}
.prbtn {
  display: flex;
  flex-direction: row-reverse;
}
.linkto{
  text-decoration: none;
  color: #3082E2;
}
.linkto>span:hover{
  color: #84b5ec;
}
/* 新改 */
.zhidingsearch{
    background-color: #ffffff;
    padding: 1px 15px;
    margin: 20px 0 10px 0;
}
.piconetn{
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 9px 1px #EEEEEE;
}


</style>
